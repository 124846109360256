<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			readOnly
		></pui-datatable>
		<georepreport-modals :modelName="modelName"></georepreport-modals>
	</div>
</template>

<script>
import georepreportActions from './GeorepreportActions';
import georepreportModals from './GeorepreportModals.vue';

export default {
	name: 'georepreport-grid',
	components: {
		'georepreport-modals': georepreportModals
	},
	data() {
		return {
			modelName: 'georepreport',
			actions: georepreportActions.gridactions,
			modelColumnDefs: {
				btime: {
					label: 'B Time',
					type: 'integer',
					render: (value) => value.toString().padStart(6, '0')
				},
				isvalid: {
					render: (data) => {
						if (data == 1) {
							return 'Yes';
						} else {
							return 'No';
						}
					}
				}
			}
		};
	}
};
</script>
